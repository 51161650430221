.rdrStaticRangeLabel,
.rdrDateInput input {
  font-family: "Be Vietnam" !important;
  font-weight: 500;
}

.rdrMonthAndYearPickers select,
.rdrWeekDay,
.rdrDayNumber,
.rdrInputRangeInput {
  font-family: "Be Vietnam" !important;
}
